
import { Options, Vue } from "vue-class-component";
import CourseCard from "../components/CourseCard.vue";
import { courseListModHandler } from "../handlers/course/course-get-auth-handlers";
import { isLoggedIn } from "../handlers/handlers-helpers";
import { CourseModType } from "../types/course/course-type";
import { TypeSubjectEnum } from "../types/enum/type-course";

@Options({
  components: { CourseCard },
})
export default class Administration extends Vue {
  private course_list: Array<CourseModType> | undefined = [];

  private type_mod: TypeSubjectEnum = TypeSubjectEnum.MOD;

  mounted(): void {
    if (isLoggedIn()) {
      courseListModHandler()
        .then((courseAllGet: Array<CourseModType> | void) => {
          this.course_list = courseAllGet || [];
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
